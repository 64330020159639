import { FC } from 'react';
import { getLocalImageUrl } from 'utils/image';

interface GoogleAuthProps {
  isGoogleAuth: boolean;
  handleGoogleSSO: () => void;
  styles: {
    authDivider: {};
  };
}

export const GoogleSSOauth: FC<GoogleAuthProps | any> = ({
  isGoogleAuth,
  handleGoogleSSO,
  styles,
}) => {
  return (
    isGoogleAuth && (
      <>
        <button
          onClick={handleGoogleSSO}
          className="button-3d with-icon full-width is-alternate"
        >
          <img
            width={24}
            src={getLocalImageUrl('/images/icons/google.svg')}
            alt="google logo"
          />
          <span className="text-lineheight-none">CONTINUE WITH GOOGLE</span>
        </button>
        <div className={styles.authDivider}>
          <hr />
          <span>OR</span>
          <hr />
        </div>
      </>
    )
  );
};
